//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//加载自动回复内容
import {
  http_account_list_auto_reply,
  http_account_submit_auto_reply,
  http_account_order_auto_reply,
  http_account_delete_auto_reply,
  http_account_setting_info,
  http_account_setting_save
} from "@/api/account.js";

import draggable from 'vuedraggable'




export default {
  name: "AuthComponent",
  data() {
    return {
      page: 1,
      limit: 15,
      input: 0,
      radio: "",
      dialogVisible: false,
      dialogContent: {
        timeout: 60,
        remark: "",
        msg_type: 'text',
        media_id: 'none',
        soft: -1,
        autoid: -1,
        ghid: ''
      },
      indexActionEdit: -1,
      dialogItemArray: [],
      dialogSettingInfo: {
        reply_spacing: "60",
        reply_frequency: '-1'

      }
    }
  },
  components: {
    draggable,
  },
  mounted() {
    this.$nextTick(function () {
      //加载自动回复内容列表
      this.onLoadDialogItemList();
      //加载配置信息
      this.request_http_account_setting_info();
    })
  },
  methods: {
    /**
     * 加载列表数据。
     */
    onLoadDialogItemList() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_list_auto_reply({open_id}).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dialogItemArray = res.data;
        }
      });
    },

    /**
     * 添加对话框话术
     * @constructor
     */
    CreateDialogContent() {
      this.dialogVisible = true;
    },
    /**
     * 开始移动事件监听
     * @param e
     */
    draggableStart(e) {
      console.log(e);
      console.log(this.dialogItemArray);
      // oldDraggableIndex: 0
      // oldIndex: 0
    },
    /**
     * 停止移动事件监听
     * @param e
     */
    draggableEnd(e) {
      console.log("停止移动");
      console.log(e);
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_order_auto_reply({ghid: open_id, data: this.dialogItemArray}).then(res => {
        console.log(res);
      })
    },

    /**
     * 加载对话配置信息。
     */
    request_http_account_setting_info() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_setting_info({ghid:open_id}).then(res => {
        if (res.code == 0) {
          let data = res.data;
          data.reply_frequency = data.reply_frequency.toString();
          this.dialogSettingInfo = res.data;
        }
      })
    },
    /**
     * 保存对话配置信息
     */
    onSaveDialogSettingInfo() {

      if (this.dialogSettingInfo.reply_frequency == 2) {
        if (this.dialogSettingInfo.reply_spacing > 120 || this.dialogSettingInfo.reply_spacing < 1) {
          this.$message.error("每次间隔时间不能大于120分钟或者小于1分钟");
          return false;
        }
      }
      http_account_setting_save(this.dialogSettingInfo).then(res => {
        if (res.code == 0) {
          this.$message.success("保存成功");
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    /**
     * 保存新建或者编辑的回复话术内容。
     * @constructor
     */
    SubmitContent() {

      if (this.dialogContent.timeout > 1800) {
        this.$message.error("对不起，时间不能超过1800秒（30分钟）")
        return false;
      }

      this.dialogContent.ghid = this.$store.state.account_merchant_item.open_id;
      let active_index = this.indexActionEdit;

      http_account_submit_auto_reply(this.dialogContent).then(res => {
        if (res.code == 0) {
          this.dialogVisible = false;
          if (this.dialogContent.autoid > 0) {
            this.$set(this.dialogItemArray, active_index, res.data);
          } else {
            this.dialogItemArray.push(res.data);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
      this.indexActionEdit = -1;
    },


    /**
     * 编辑内容
     * @param item
     * @param index
     * @constructor
     */
    EditDialogContent(item, index) {
      this.indexActionEdit = index;
      this.dialogVisible = true;
      this.dialogContent = JSON.parse(JSON.stringify(item));
    },
    DeleteDialogContent(item, index) {
      this.$confirm('此操作将永久删除该回复内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        http_account_delete_auto_reply(item).then(res => {
          if (res.code == 0) {
            this.$message.success("删除成功");
            this.dialogItemArray.splice(index, 1);
          } else {
            this.$message.error("删除失败");
          }
        })
      }).catch(() => {
      });
    }
  },
  watch: {
    SlectedAccount(newData, oldData) {
      this.page = 1;
      console.log(newData, oldData);
      this.onLoadDialogItemList(newData);
      this.onLoadDialogSettingInfo();
    },
    '$store.state.account_merchant_item': function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
      // this.on_load_account_info();
      //加载自动回复内容列表
      this.onLoadDialogItemList();
      //加载配置信息
      this.request_http_account_setting_info();
    }
  }
}
