//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {http_session_account_list} from "@/api/session.js"

import Base from "@/components/Account/module/Base.vue"
import Auto from "@/components/Account/module/Auto.vue"
import Chat from "@/components/Account/module/Chat.vue"
import Customer from "@/components/Account/module/Customer.vue"

export default {

  name: "Index",
  mounted() {
    this.$nextTick(function () {
      this.request_http_session_account_list();
    });
  },
  components: {
    Auto, Base, Chat, Customer
  },
  data() {
    return {
      currentTabComponent: "Base",
      formInline: {
        user: '',
        region: ''
      },
      account_page_size: 300,
      account_page: 1,
      page: 1,
      page_size: 30,
      count: 0,
      account_list: [],
      active_index: -1,
      menu_active_index: 0,
      menu_list: [
        {name: "基本信息", component: "Base"},
        {name: "自动回复", component: "Auto"},
        {name: "聊天回复", component: "Chat"},
        {name: "客资回复", component: "Customer"},
      ],
    }
  },

  methods: {
    /**
     * 加载抖音账号列表
     */
    request_http_session_account_list() {
      http_session_account_list({page: this.account_page, page_size: this.account_page_size}).then(res => {
        if (res.code == 0) {
          let data = res.data.data;
          this.account_list = data;
          this.count = res.data.count;
          this.$store.commit('set_account_merchant_list', res.data.data);
          if (this.active_index == -1 && data.length > 0) {
            this.on_select_account(data[0], 0);
            this.active_index = 0;
          }
        }
      })
    },

    on_select_account(row, index) {
      this.active_index = index;
      this.$store.commit('set_account_merchant_item', row);
    },

    on_select_menu(row, index) {
      this.menu_active_index = index;
      this.currentTabComponent = row.component;
    }

  }
}
