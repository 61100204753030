import request from '@/utils/request'

/**
 * 员工列表
 * @param data
 * @returns {*}
 */
export function http_setting_user_list(data) {
  return request({
    url: '/setting/user/list',
    method: 'post',
    data
  })
}


/**
 * 员工添加
 * @param data
 * @returns {*}
 */
export function http_setting_user_add(data) {
  return request({
    url: '/setting/user/add',
    method: 'post',
    data
  })
}


/**
 * 员工更新
 * @param data
 * @returns {*}
 */
export function http_setting_user_update(data) {
  return request({
    url: '/setting/user/update',
    method: 'post',
    data
  })
}



/**
 * 员工更新
 * @param data
 * @returns {*}
 */
export function http_setting_user_delete(data) {
  return request({
    url: '/setting/user/delete',
    method: 'post',
    data
  })
}



/**
 * 授权列表
 * @param data
 * @returns {*}
 */
export function http_setting_load_auth_list(data) {
  return request({
    url: '/setting/load/auth/list',
    method: 'post',
    data
  })
}



/**
 * 授权列表
 * @param data
 * @returns {*}
 */
export function http_setting_delete_auth_list(data) {
  return request({
    url: '/setting/delete/auth/info',
    method: 'post',
    data
  })
}




/**
 * 授权列表
 * @param data
 * @returns {*}
 */
export function http_setting_add_auth_list(data) {
  return request({
    url: '/setting/add/auth/info',
    method: 'post',
    data
  })
}



/**
 * 添加或者更新权限。
 * @param data
 * @returns {*}
 */
export function http_setting_add_auth_info(data) {
  return request({
    url: '/setting/add/auth/info',
    method: 'post',
    data
  })
}



/**
 * 关联的账号列表。
 * @param data
 * @returns {*}
 */
export function http_setting_user_account_list(data) {
  return request({
    url: '/setting/user/account/list',
    method: 'post',
    data
  })
}


/**
 * 关联的账号进行处理。
 * @param data
 * @returns {*}
 */
export function http_setting_user_account_well(data) {
  return request({
    url: '/setting/user/account/well',
    method: 'post',
    data
  })
}
