//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  http_account_change_stauts,
  http_account_base_info,
  http_account_refresh_auth_info,
  http_account_submit_copy_words,
  http_account_view_copy_progress
} from "@/api/account"
import {http_setting_add_auth_info, http_setting_load_auth_list} from "@/api/setting"

export default {
  name: "BaseComponent",

  data() {
    return {
      page: 1,
      limit: 10,
      count: 0,
      account_info: {
        status_switch: true
      },
      dialogVisible: false,
      copyForm: {
        id: ""
      },
      account_list: [],
      dialogProgressVisible: false,

    }
  },
  mounted() {

    this.$nextTick(function () {
      this.on_load_account_info();
      // this.test();
    })


  },
  methods: {
    changeStatusSwitch(status) {
      console.log(status);
      let open_id = this.$store.state.account_merchant_item.open_id;
      let status_switch = status ? 1 : -1;
      http_account_change_stauts({open_id: open_id, status_switch: status_switch}).then(res => {
        if (res.code == 0) {
          this.$message.success("更改账号状态成功");
        }
      })
    },
    request_http_setting_load_auth_list() {
      http_setting_load_auth_list({page: 1, page_size: 300}).then(res => {

        if (res.code == 0) {
          this.account_list = res.data.data;
        }

      })
    },

    on_load_account_info() {
      if (this.$store.state.account_merchant_item == null) {
        return;
      }
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_base_info({open_id: open_id}).then(res => {
        if (res.code == 0) {
          let data = res.data;
          data.status_switch = res.data.status_switch === 1;
          this.account_info = data;
        }
      })
    },
    refreshInfo() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_refresh_auth_info({open_id}).then(res => {
        if (res.code == 0) {
          this.$message.success("从服务器上刷新成功");
          this.account_info = res.data;
        }
      })
    },
    refreshAuth() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      let data = {
        auth_type: 'update_auth',
        ghid: open_id
      };
      http_setting_add_auth_info(data).then(res => {
        if (res.code == 0) {
          let url = res.data.url;
          window.open(url, "_blank ");
        }
      })
    },

    /**
     * 显示是否复制话术弹窗。
     */
    copyWords() {
      this.dialogVisible = true;
      this.request_http_setting_load_auth_list();
    },
    onSubmitCopy() {
      // console.log(this.copyForm);
      let _this = this;
      this.$confirm('此操作将永久覆盖目标话术, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let from_id = this.copyForm.id;
        let to_id = this.account_info.id;
        http_account_submit_copy_words({from_id, to_id}).then(res => {
          console.log(res);
          if (res.code == 0) {
            _this.dialogVisible = false;
            let id = res.data.id;
            console.log("id:" + id);
            _this.dialogProgressVisible = true;
            console.log("开启等待窗口");
            let setIntervalID = setInterval(function () {
              console.log("循环运行......");
              _this.showCopyProgress(setIntervalID, id);
            }, 5000);
          }
          this.copyForm = "";
        })
      }).catch(() => {
        this.copyForm = "";
        this.dialogVisible = false;
      });
    },
    showCopyProgress(setIntervalID, id) {
      let _this = this;
      http_account_view_copy_progress({id}).then(response => {
        _this.dialogProgressVisible = false;
        console.log("请求到的结果");
        console.log(response);
        clearInterval(setIntervalID);
        if (response.code == 0 && response.data.status == 2) {
          _this.$message.success("执行成功，复制完毕");
        } else {
          _this.$message.error(response.message);
        }
      })
    }
  },
  watch: {
    '$store.state.account_merchant_item': function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
      this.on_load_account_info();
    }
  }
}
