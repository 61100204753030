import request from '@/utils/request'


/**
 * 账号基本信息
 * @param params
 * @returns {*}
 */
export function http_account_base_info(params) {
  return request({
    url: '/account/base/info',
    method: 'get',
    params
  })
}


export function http_account_change_stauts(data) {
  return request({
    url: '/account/change/status',
    method: 'post',
    data
  })
}


/**
 * 自动回复列表
 * @param params
 * @returns {*}
 */
export function http_account_list_auto_reply(params) {
  return request({
    url: '/account/list/auto/reply',
    method: 'post',
    params
  })
}


/**
 * 提交自动回复
 * @param params
 * @returns {*}
 */
export function http_account_submit_auto_reply(data) {
  return request({
    url: '/account/submit/auto/reply',
    method: 'post',
    data
  })
}


/**
 * 排序
 * @param params
 * @returns {*}
 */
export function http_account_order_auto_reply(data) {
  return request({
    url: '/account/order/auto/reply',
    method: 'post',
    data
  })
}


/**
 * 排序
 * @param params
 * @returns {*}
 */
export function http_account_delete_auto_reply(data) {
  return request({
    url: '/account/delete/auto/reply',
    method: 'post',
    data
  })
}


/**
 * 排序
 * @param params
 * @returns {*}
 */
export function http_account_setting_info(data) {
  return request({
    url: '/account/setting/info',
    method: 'post',
    data
  })
}


/**
 * 保存配置
 * @param params
 * @returns {*}
 */
export function http_account_setting_save(data) {
  return request({
    url: '/account/setting/save',
    method: 'post',
    data
  })
}

/**
 * ************************************关键词回复内容*********************************
 */


/**
 * 加载关键词的配置
 * @param data
 * @returns {*}
 */
export function http_account_load_key_setting(data) {
  return request({
    url: '/account/load/key/setting',
    method: 'post',
    data
  })
}


/**
 * 保存关键词的配置
 * @param data
 * @returns {*}
 */
export function http_account_save_key_setting(data) {
  return request({
    url: '/account/save/key/setting',
    method: 'post',
    data
  })
}


/**
 * 加载关键词列表数据
 * @param data
 * @returns {*}
 */
export function http_account_load_key_list(data) {
  return request({
    url: '/account/load/key/list',
    method: 'post',
    data
  })
}


/**
 * 更新关键词列表数据
 * @param data
 * @returns {*}
 */
export function http_account_save_key_list(data) {
  return request({
    url: '/account/save/key/list',
    method: 'post',
    data
  })
}


/**
 * 删除关键词列表数据
 * @param data
 * @returns {*}
 */
export function http_account_delete_key_list(data) {
  return request({
    url: '/account/delete/key/list',
    method: 'post',
    data
  })
}


/**
 * 回复内容列表加载
 * @param data
 * @returns {*}
 */
export function http_account_load_key_content_list(data) {
  return request({
    url: '/account/load/key/content/list',
    method: 'post',
    data
  })
}


/**
 * 回复内容排序
 * @param data
 * @returns {*}
 */
export function http_account_sort_key_content_list(data) {
  return request({
    url: '/account/sort/key/content/list',
    method: 'post',
    data
  })
}


/**
 * 保存关键词回复内容
 * @param data
 * @returns {*}
 */
export function http_account_save_key_content_body(data) {
  return request({
    url: '/account/save/key/content/body',
    method: 'post',
    data
  })
}


/**
 * 删除关键词回复内容。
 * @param data
 * @returns {*}
 */
export function http_account_delete_key_content_body(data) {
  return request({
    url: '/account/delete/key/content/body',
    method: 'post',
    data
  })
}


/**
 *
 * 加载默认回复的内容
 * @param data
 * @returns {*}
 */
export function http_account_load_no_match_content(data) {
  return request({
    url: '/account/load/no/match/content',
    method: 'post',
    data
  })
}


/**
 *
 * 设置默认回复的内容
 * @param data
 * @returns {*}
 */
export function http_account_save_no_match_content(data) {
  return request({
    url: '/account/save/no/match/content',
    method: 'post',
    data
  })
}


/*******************************************客户回复************************************************/

/**
 * 加载获取客资之后的回复内容
 * @param data
 * @returns {*}
 */
export function http_account_load_customer_after_content(data) {
  return request({
    url: '/account/load/customer/after/content',
    method: 'post',
    data
  })
}


/**
 * 保存获取客资的设置
 * @param data
 * @returns {*}
 */
export function http_account_save_customer_after_setting(data) {
  return request({
    url: '/account/save/customer/after/setting',
    method: 'post',
    data
  })
}

/**
 * 保存获取客资回复的
 * @param data
 * @returns {*}
 */
export function http_account_save_customer_after_content(data) {
  return request({
    url: '/account/save/customer/after/content',
    method: 'post',
    data
  })
}


/**
 * 删除获取客资回复的
 * @param data
 * @returns {*}
 */
export function http_account_delete_customer_after_content(data) {
  return request({
    url: '/account/delete/customer/after/content',
    method: 'post',
    data
  })
}


/**
 * 客户回复内容的顺序变化
 * @param data
 * @returns {*}
 */
export function http_account_sort_customer_after_reply(data) {
  return request({
    url: '/account/sort/customer/after/reply',
    method: 'post',
    data
  })
}


/**
 * 刷新授权账号信息
 * @param data
 * @returns {*}
 */
export function http_account_refresh_auth_info(data) {
  return request({
    url: '/account/refresh/auth/info',
    method: 'post',
    data
  })
}


/**
 * 提交复制话术
 * @param data
 * @returns {*}
 */
export function http_account_submit_copy_words(data) {
  return request({
    url: '/account/submit/copy/words',
    method: 'post',
    data
  })
}


/**
 * 获取复制的进度结果。
 * @param data
 * @returns {*}
 */
export function http_account_view_copy_progress(data) {
  return request({
    url: '/account/view/copy/progress',
    method: 'post',
    data
  })
}











