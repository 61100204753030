//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



import draggable from 'vuedraggable'
import {
  http_account_load_key_setting,
  http_account_save_key_setting,
  http_account_load_key_list,
  http_account_save_key_list,
  http_account_save_key_content_body,
  http_account_sort_key_content_list,
  http_account_load_key_content_list,
  http_account_delete_key_content_body,
  http_account_delete_key_list,
  http_account_save_no_match_content
} from '@/api/account'
import {http_account_load_no_match_content} from "../../../api/account";


export default {
  name: "ChatComponent",
  components: {
    draggable
  },
  data() {
    return {
      page: 1,
      limit: 10,
      count: 0,
      settingValue: "1",
      dialogVisible: false,
      ChatData: {
        id: -1,
        name: "",
        weight: 1,
        precise: "",
        fuzzy: "",
        freeze: false
      },
      dialogContentVisible: false,
      ChatContentData: {
        sleep_time: 60,
        replay_content: "",
        id: -1
      },
      dialogItemVisible: false,
      ChatKeyList: [],
      ChatKeyContentList: [],
      SelectChatKeyContent: {},
      SelectChatKeyIndex: -1,
      defaultContentIsOpen: '-1',
      defaultReplayContent: "",
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadChatKeyList();
      this.onLoadChatSettingInfo();
      this.onLoadDefaultContent();
    })
  },
  methods: {
    //加载关键词设置信息。
    onLoadChatSettingInfo() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_load_key_setting({ghid: open_id}).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.settingValue = res.data.status.toString();
        }
      })
    },

    //加载关键词回复列表。
    onLoadChatKeyList() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_load_key_list({ghid: open_id, page: this.page, page_size: this.limit}).then(res => {
        if (res.code == 0) {
          this.ChatKeyList = res.data.data;
          // if (this.page == 1) {
          //   this.ChatKeyList = res.data.data;
          // } else {
          //   this.ChatKeyList.concat(res.data.data);
          // }
          this.count = res.data.count;
        } else if (res.code == 4004) {
          this.ChatKeyList = [];
        }
      })
    },

    currentChange(page){
      this.page = page;
      this.onLoadChatKeyList();
    },

    //加载关键词回复内容
    onLoadChatKeyContentList(item, index) {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_load_key_content_list({id: item.id, ghid: open_id}).then(res => {
        console.log(res);
        console.log(index);
        if (res.code == 0) {
          this.ChatKeyContentList = res.data;
        }
      })
    },
    ClearChatData() {
      console.log("关闭了");
      this.dialogVisible = false;
      this.ChatData = {id: -1, name: "", weight: 500, precise: "", fuzzy: "", freeze: false}
    },
    CreateDialogContent() {
      this.dialogVisible = true;
      this.ChatData = {id: -1, name: "", weight: 500, precise: "", fuzzy: "", freeze: false}
    },
    EditDialogContent(item, index) {
      this.dialogVisible = true;
      item.actionIndex = index;
      this.ChatData = item;
      console.log(item, index);
    },
    UpdateChatKeyData(item, index) {
      this.ChatKeyContentList = [];
      this.dialogContentVisible = true;
      this.SelectChatKeyContent = item;
      this.SelectChatKeyIndex = index;
      this.onLoadChatKeyContentList(item, index);
    },
    /**
     * al_chat_key_precise
     * 保存配置信息。
     * @constructor
     */
    SaveChatSetting() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_save_key_setting({ghid: open_id, value: this.settingValue})
          .then(res => {
            if (res.code == 0) {
              this.$message.success("设置成功");
            } else {
              this.$message.error(res.msg);
            }
          });
    },
    /**
     * 保存列表数据。
     * @constructor
     */
    onSaveChatKeyList() {

      let open_id = this.$store.state.account_merchant_item.open_id;

      if (this.ChatData.name.length <= 0 || this.ChatData.precise.length <= 0 || this.ChatData.fuzzy.length <= 0 || this.ChatData.weight <= 0) {
        this.$message.error("表单参数必填");
        return false;
      }

      this.ChatData.ghid = open_id;
      http_account_save_key_list(this.ChatData).then(res => {
        if (res.code == 0) {
          this.dialogVisible = false;
          if (this.ChatData.id > 0) {
            //更新
            this.ChatKeyList[this.ChatData.actionIndex] = res.data;
          } else {
            //追加到头部
            this.ChatKeyList.unshift(res.data);
          }
        }
      })
    },
    DeleteChatKeyData(item, index) {
      console.log(item, index);
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_delete_key_list({ghid: open_id, id: item.id}).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.ChatKeyList.splice(index, 1);
          this.$message.success("删除成功");
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    onDeleteChatKeyData(item, index) {
      this.$confirm('此操作将永久删除该回复吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.DeleteChatKeyData(item, index);
      }).catch(() => {
      });
    },
    /**
     * 保存回复内容
     */
    onSaveChatContent() {
      let _this = this;
      let open_id = this.$store.state.account_merchant_item.open_id;
      if (this.ChatContentData.sleep_time > 600) {
        this.$message.error("间隔时间不能大于10分钟");
        return false;
      }

      http_account_save_key_content_body({
        id: this.ChatContentData.id,
        ghid: open_id,
        key_id: this.SelectChatKeyContent.id,
        sleep_time: this.ChatContentData.sleep_time,
        replay_content: this.ChatContentData.replay_content
      }).then(res => {
        if (res.code == 0) {
          //添加到列表尾部。
          if (this.ChatContentData.id <= 0) {
            this.ChatKeyContentList.push(res.data);
          } else {
            this.ChatKeyContentList[this.SelectChatKeyIndex] = res.data;
          }
          _this.dialogItemVisible = false;
        } else {
          this.$message.success(res.msg);
        }
      })
    },

    CreateChatKeyContentBody() {
      this.dialogItemVisible = true;
      this.ChatContentData = {sleep_time: 60, replay_content: "", id: -1}
    },
    EditChatKeyContentBody(item, index) {
      console.log(item, index);
      this.dialogItemVisible = true;
      this.ChatContentData = JSON.parse(JSON.stringify(item));
      this.SelectChatKeyIndex = index;
    },
    onDeleteChatKeyContentBody(item, index) {
      //提示，确定然后删除
      http_account_delete_key_content_body(item).then(res => {
        if (res.code == 0) {
          this.ChatKeyContentList.splice(index, 1);
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    draggableEnd(e) {
      console.log(e);
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_sort_key_content_list({ghid: open_id, data: this.ChatKeyContentList}).then(res => {
        if (res.code != 0) {
          this.$message.error(res.msg);
        }
      })
    },
    draggableStart(e) {
      console.log(e);
    },

    onLoadDefaultContent() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_load_no_match_content({ghid: open_id}).then(res => {
        if (res.code == 0) {
          let data = res.data;
          this.defaultContentIsOpen = data.is_open.toString();
          this.defaultReplayContent = data.replay_content;
        }
      })
    },

    onSubmitDefaultContent() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      let args = {
        ghid: open_id,
        data: {is_open: this.defaultContentIsOpen, replay_content: this.defaultReplayContent}
      };
      http_account_save_no_match_content(args).then(res => {
        if (res.code == 0) {
          this.$message.success("保存成功");
        }
      })
    }
  },
  watch: {
    SlectedAccount(newData, oldData) {
      this.$store.dispatch("account/SetSlectedAccount", newData);
      this.onLoadChatKeyList();
      this.onLoadChatSettingInfo();
      console.log(oldData);
    },
    '$store.state.account_merchant_item': function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
      this.onLoadChatKeyList();
      this.onLoadChatSettingInfo();
      this.onLoadDefaultContent();
    }
  }
}
