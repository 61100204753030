//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable'
import {
  http_account_sort_customer_after_reply,
  http_account_load_customer_after_content,
  http_account_save_customer_after_setting,
  http_account_save_customer_after_content,
  http_account_delete_customer_after_content
} from '@/api/account'


export default {
  name: "CustComponent",
  components: {
    draggable,
  },
  data() {
    return {
      dialogVisible: false,
      dialogContent: {
        customer_type: "",
        timeout: 60,
        remark: "",
        msg_type: 'text',
        media_id: 'none',
        soft: -1,
        autoid: -1,
        ghid: ''
      },
      indexActionEdit: -1,
      type: 'wechat',
      CustomerData: {
        wechat: {
          setting: "-1",
          content: []
        },
        telephone: {
          setting: "-1",
          content: []
        }
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadCustomerAfterContent();
    })
  },
  methods: {
    draggableStart(e) {
      console.log(e);
      console.log(this.dialogItemArray);
    },
    draggableWechatEnd(e) {
      console.log(e);
      //把整个数据传递给后端，让后端处理。
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_sort_customer_after_reply({
        ghid: open_id,
        data: this.CustomerData.wechat.content,
        customer_type: 'wechat'
      }).then(res => {
        console.log(res);
      })
    },
    draggablePhoneEnd(e) {
      console.log(e);
      //把整个数据传递给后端，让后端处理。
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_sort_customer_after_reply({
        ghid: open_id,
        data: this.CustomerData.telephone.content,
        customer_type: 'telephone'
      }).then(res => {
        console.log(res);
      })
    },
    /**
     * 加载获取客资之后的回复内容。
     */
    onLoadCustomerAfterContent() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_load_customer_after_content({ghid: open_id}).then(res => {
        if (res.code == 0) {
          this.CustomerData = res.data;
          this.CustomerData.telephone.setting = this.CustomerData.telephone.setting.toString();
          this.CustomerData.wechat.setting = this.CustomerData.wechat.setting.toString();
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    onSaveCustomerAfterSetting(type) {
      let open_id = this.$store.state.account_merchant_item.open_id;
      http_account_save_customer_after_setting({
        ghid: open_id,
        type: type,
        data: this.CustomerData[type]['setting']
      }).then(res => {
        console.log(res);
      })
    },

    CreateCustomerAfterSetting(type) {
      this.type = type;
      this.dialogVisible = true;
      this.dialogContent.customer_type = type;
    },
    /**
     * 更新手机号码回复内容
     * @param item
     * @param index
     */
    EditDialogContent(item, index) {
      this.initDialogContent();
      this.indexActionEdit = index;
      this.dialogVisible = true;
      this.dialogContent = item;
    },
    SubmitContent() {
      let open_id = this.$store.state.account_merchant_item.open_id;
      let _this = this;
      let indexActionEdit = this.indexActionEdit;
      if (this.dialogContent.timeout > 1800) {
        this.$message.error("对不起，时间不能超过1800秒（30分钟）")
        return false;
      }
      this.dialogContent.ghid = open_id;
      http_account_save_customer_after_content(this.dialogContent).then(res => {
        console.log(res);
        if (res.code == 0) {
          _this.dialogVisible = false;
          if (res.data.customer_type == 'wechat') {
            if (_this.dialogContent.autoid > 0) {
              _this.CustomerData.wechat.content[indexActionEdit] = res.data;
            } else {
              _this.CustomerData.wechat.content.push(res.data);
            }
          }

          if (res.data.customer_type == 'telephone') {
            if (_this.dialogContent.autoid > 0) {
              _this.CustomerData.telephone.content[indexActionEdit] = res.data;
            } else {
              _this.CustomerData.telephone.content.push(res.data);
            }
          }
        } else {
          _this.$message.error(res.msg);
        }
      })
      this.indexActionEdit = -1;

    },
    initDialogContent() {
      this.dialogContent = {
        timeout: 10,
        remark: "",
      };
    },
    closedialogVisible() {
      this.dialogVisible = false;
      this.dialogContent = {
        timeout: 10,
        remark: "",
        customer_type: "",
        msg_type: "text",
        media_id: "none",
        autoid: -1,
        soft: -1
      };
    },
    /**
     * 更新微信客资设置回复内容
     * @param item
     * @param index
     */
    updateCustomerMessage(item, index) {
      this.initDialogContent();
      this.dialogVisible = true;
      this.indexActionEdit = index;
      this.dialogContent = item;
      console.log(item, index);
    },
    /**
     * 删除微信客资回复内容
     * @param item
     * @param index
     */
    deleteCustomerMessage(item, index) {

      let _this = this;
      _this.$confirm('此操作将永久删除该话术, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        http_account_delete_customer_after_content(item).then(res => {
          if (res.code == 0) {
            if (item.customer_type == 'wechat') {
              _this.CustomerData.wechat.content.splice(index, 1);
            }
            if (item.customer_type == 'telephone') {
              _this.CustomerData.telephone.content.splice(index, 1);
            }
          }
        })
      }).catch(() => {
      });

    },
  },
  watch: {
    SlectedAccount(newData, oldData) {
      console.log(newData, oldData);
      this.onLoadCustomerAfterContent();
    },
    '$store.state.account_merchant_item': function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
      this.onLoadCustomerAfterContent();
    }
  }
}
